

var waypoint = new Waypoint({
  element: document.getElementById('navbar'),
  handler: function(direction) {
    if (direction === 'down') {
      $('#navbar').addClass('stuck');
    } else {
      $('#navbar').removeClass('stuck navigating');
    }
  }
})

$(document).on('click', '#nav-switch', function() {
  $('#navbar').toggleClass('navigating');
});

var initPhotoSwipeFromDOM = function(gallerySelector) {

    // parse slide data (url, title, size ...) from DOM elements 
    // (children of gallerySelector)
    var parseThumbnailElements = function(el) {
        var thumbElements = el.childNodes,
            numNodes = thumbElements.length,
            items = [],
            figureEl,
            linkEl,
            size,
            item;

        for(var i = 0; i < numNodes; i++) {

            figureEl = thumbElements[i]; // <figure> element

            // include only element nodes 
            if(figureEl.nodeType !== 1) {
                continue;
            }

            linkEl = figureEl.children[0]; // <a> element

            size = linkEl.getAttribute('data-size').split('x');

            // create slide object
            item = {
                src: linkEl.getAttribute('href'),
                w: parseInt(size[0], 10),
                h: parseInt(size[1], 10)
            };



            if(figureEl.children.length > 1) {
                // <figcaption> content
                item.title = figureEl.children[1].innerHTML; 
            }

            if(linkEl.children.length > 0) {
                // <img> thumbnail element, retrieving thumbnail url
                item.msrc = linkEl.children[0].getAttribute('src');
            } 

            item.el = figureEl; // save link to element for getThumbBoundsFn
            items.push(item);
        }

        return items;
    };

    // find nearest parent element
    var closest = function closest(el, fn) {
        return el && ( fn(el) ? el : closest(el.parentNode, fn) );
    };

    // triggers when user clicks on thumbnail
    var onThumbnailsClick = function(e) {
		
        e = e || window.event;
        e.preventDefault ? e.preventDefault() : e.returnValue = false;

        var eTarget = e.target || e.srcElement;

        // find root element of slide
        var clickedListItem = closest(eTarget, function(el) {
            return (el.tagName && el.tagName.toUpperCase() === 'FIGURE');
        });

        if(!clickedListItem) {
            return;
        }

        // find index of clicked item by looping through all child nodes
        // alternatively, you may define index via data- attribute
        var clickedGallery = clickedListItem.parentNode,
            childNodes = clickedListItem.parentNode.childNodes,
            numChildNodes = childNodes.length,
            nodeIndex = 0,
            index;

        for (var i = 0; i < numChildNodes; i++) {
            if(childNodes[i].nodeType !== 1) { 
                continue; 
            }

            if(childNodes[i] === clickedListItem) {
                index = nodeIndex;
                break;
            }
            nodeIndex++;
        }


		
        if(index >= 0) {
			
			function GalleryOpenner() {
				var gallery = openPhotoSwipe( index, clickedGallery );

					$('.pswp__button--download--image').on('click', function() {
						var link = document.createElement('a');
						var src = gallery.currItem.src;
						link.href = src;
						link.download = src.substring(src.lastIndexOf('/')+1);;
						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
					});
			} 

			// open PhotoSwipe if valid index found
			if(window.pageYOffset != 0) {
				$('html').velocity("scroll", { offset: "0px", mobileHA: false, duration: 200,
				complete: GalleryOpenner
				});
			} else {
				GalleryOpenner();
			}
            
        }
        return false;
    };

    // parse picture index and gallery index from URL (#&pid=1&gid=2)
    var photoswipeParseHash = function() {
		var hash = window.location.hash.substring(1),
		params = {};

        if(hash.length < 5) {
            return params;
        }

        var vars = hash.split('&');
        for (var i = 0; i < vars.length; i++) {
            if(!vars[i]) {
                continue;
            }
            var pair = vars[i].split('=');  
            if(pair.length < 2) {
                continue;
            }           
            params[pair[0]] = pair[1];
        }

        if(params.gid) {
            params.gid = parseInt(params.gid, 10);
        }

        return params;
    };

    var openPhotoSwipe = function(index, galleryElement, disableAnimation, fromURL) {
        var pswpElement = document.querySelectorAll('.pswp')[0],
            gallery,
            options,
			items;
			

        items = parseThumbnailElements(galleryElement);

        // define options (if needed)
        options = {

            // Buttons/elements
            closeEl: true,
            captionEl: false,
            fullscreenEl: true,
            zoomEl: true,
            shareEl: false,
            counterEl: false,
            arrowEl: true,
            preloaderEl: false,
            barsSize: {top: 96, bottom: 96}, 
            closeOnScroll: false,

            // define gallery index (for URL)
            galleryUID: galleryElement.getAttribute('data-pswp-uid'),

            getThumbBoundsFn: function(index) {
				
                // See Options -> getThumbBoundsFn section of documentation for more info
                var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
                    pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                    rect = thumbnail.getBoundingClientRect(); 

                return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
			},
			

		};
		
		if(index <= 1) {
			options.arrowEl = false;
			pswpElement.classList.add("pswp--no-arrows");
			console.log(pswpElement)
		}


        // PhotoSwipe opened from URL
        if(fromURL) {
            if(options.galleryPIDs) {
                // parse real index when custom PIDs are used 
                // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
                for(var j = 0; j < items.length; j++) {
                    if(items[j].pid == index) {
                        options.index = j;
                        break;
                    }
                }
            } else {
                // in URL indexes start from 1
                options.index = parseInt(index, 10) - 1;
            }
        } else {
            options.index = parseInt(index, 10);
        }

        // exit if index not found
        if( isNaN(options.index) ) {
            return;
        }

        if(disableAnimation) {
            options.showAnimationDuration = 0;
        }
		options.history = false;
        // Pass data to PhotoSwipe and initialize it
        gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
		gallery.init();
		
		return gallery;
    };

    // loop through all gallery elements and bind events
	var galleryElements = document.querySelectorAll( gallerySelector );
	

    for(var i = 0, l = galleryElements.length; i < l; i++) {
		
        galleryElements[i].setAttribute('data-pswp-uid', i+1);
        galleryElements[i].onclick = onThumbnailsClick;
	}

	


    // Parse URL and open gallery if it contains #&pid=3&gid=1
    // var hashData = photoswipeParseHash();
    // if(hashData.pid && hashData.gid) {
    //     openPhotoSwipe( hashData.pid ,  galleryElements[ hashData.gid - 1 ], true, true );
    // }
};



if($('.swiper-container').length) {
	var swiper = new Swiper(".swiper-container", {
		// If loop true set photoswipe - counterEl: false
		loop: true,
		/* slidesPerView || auto - if you want to set width by css like flickity.js layout - in this case width:80% by CSS */
		slidesPerView: "auto",
		spaceBetween: 0,
		centeredSlides: false,
		pagination: {
			el:'.controls-counter',
			type: 'fraction'
		},
		// Navigation arrows
		navigation: {
			nextEl: '.controls-next',
			prevEl: '.controls-prev',
		},
		on: {
			init: function () {				
				initPhotoSwipeFromDOM('.photoswipegallery');
			},
		},
	});

} else {
	// pokud je jen jeden img v galerii
	if($('.photoswipegallery').length) {
		initPhotoSwipeFromDOM('.photoswipegallery');
	}

}

